import React  from 'react'
// import Form from 'react-bootstrap/Form';
// import InputGroup from 'react-bootstrap/InputGroup';
import { useState} from 'react'

export const Chekbox = () => {
    const [checked, setChecked] = useState(false);
  return (
 <> 
 <label>
      <input type="checkbox"
        defaultChecked={checked}
        onChange={() => setChecked(!checked)} className="w-xl"
      />
    
    </label>
    </>
    )
}
