
import React from "react";
import Dashboard from "./componet/Dashboard";


function App() {
  return (
    <div className="row vh-100">
      <section className="col-1 bg-primary py-5 px-3 fs-5">
        <div className=" px-3 fs-3 text-center text-white">


     Dashboard
        </div>
      </section>
      <section className="col-11 bg-white py-5 px-3">
        <Dashboard />
      </section>
    </div>
  );
}

export default App;
