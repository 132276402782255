import React from 'react'
import axios from 'axios'
import { useState, useEffect} from 'react'
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
import Modalku from './Modalku';
import { Chekbox } from './Chekbox';
import Spinner from 'react-bootstrap/Spinner';



export default function Tableapi() {
//    akses api
  const [read, setReads] = useState("")
  const getreads = async () => {
    try {
      let response = await axios.get('https://script.google.com/macros/s/AKfycbw2RUn1CmUvhqowF8IbdGnXyfK9l1ph5CxAfqnASuWPecNg9Uf2Kcof3Suy6vHH65uSGA/exec?action=read')
 
      setReads(response.data.records)
            // console.log(response.data.records)
    } catch (e) {
      console.log(e.message);
    }
  }

  
//   const a = useMemo ( ( ) => ( getreads() ) , [ ] ) ; 
  useEffect(() => {
    getreads();
  }, []);

  //end api

    

// button end




// ListItem memasuka item
  function ListItem() {
    if (!read) {
        return (<tr>
            <td colSpan={6}>

            <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
            </td>
             </tr>
             )
      }
    // Correct! There is no need to specify the key here:
    return (
        read.map((data, i) => {

        return (
    
        <tr key={i}>
          <td>{i + 1}</td>
          <td>{data.Nama_Lengkap}</td>
          <td>{data.Gelar_Atau_Jabatan}</td>
          <td>{data.Pangkat_Atau_Korps
}</td>
          <td>
             <Modalku no={i + 1} nama={data.Nama_Lengkap} pangkat={data.Pangkat_Atau_Korps
} jabatan= {data.Gelar_Atau_Jabatan
} poto={data.Upload_Poto} nrp={data.NRP} kesatuan={data.Kesatuan}/>


             
          </td>
          <td>
            <Chekbox />
          </td>
        </tr>
        )
    })

        )
  }
// end list imem




// mengeluarkan data
  return (
    <tbody className=''>
    <ListItem />


    </tbody>
  )
}
