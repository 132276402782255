import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import { useState} from 'react'

export default function Modalku(props) {
  
// button start
const [show, setShow] = useState(false);

const handleClose = () => setShow(false);
const handleShow =  () => setShow(true);
; 


  return (
    <>
      <Button variant="primary" onClick={handleShow}>
               Detail
              </Button>
              <Modal  size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal Detail Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            No : {props.no} <br></br> 
            Nama : {props.nama} <br></br>
            Pangkat : {props.pangkat} <br></br>
            NRP : {props.nrp} <br></br>
            Jabatan : {props.jabatan} <br></br> 
            Kesatuan : {props.kesatuan} <br></br>
            <br></br>
            Gambar :  <Button variant="success"  href={props.poto} target='_blank'>Unduh</Button>
            <br></br> 
            <br></br>
            <Card body className="my-4 fs-6">Pangkat /Korps : Nama Lengkap : Jabatan /Gelar</Card>
            <Card body className="mb-4 fs-4 fw-semibold">{props.pangkat} {props.nama} {props.jabatan}</Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>
    </>
  )
}
