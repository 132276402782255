import React from 'react'
import Alert from 'react-bootstrap/Alert';

export const Alertlink = () => {
  return (
    <>
    {[
     
      'warning',
    
    ].map((variant) => (
      <Alert key={variant} variant={variant}>
         Mohon Untuk Check Ulang Data Sebelum di cetak <br/>
        Lihat Daftar Data Dari Sumber Database
        <Alert.Link href="https://docs.google.com/spreadsheets/d/1kass-rgLGmXTXjBvKXIKSZOWYrwSBAMZzpXhYoFoUXA/edit?resourcekey=undefined#gid=1352654276"> Google Sheet</Alert.Link>
      </Alert>
      ))}
  </>
  )
}
