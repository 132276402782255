import React from 'react'
import Table from 'react-bootstrap/Table';
import { Alertlink } from './Alertlink';
import Tableapi from './Tableapi';

export default function dashboard() {
  return (
    <div>
    <div className="my-3">Table Member</div>
    <Alertlink />
    

    <Table striped bordered hover className="my-3 px-3" >
    <thead>
      <tr>
        <th>#</th>
        <th>Nama</th>
        <th>Jabatan /Gelar </th>
        <th>Pangkat,Krops</th>
        <th>Aksi</th>
        <th>Status</th>
      </tr>
    </thead>
   <Tableapi/>
  </Table>
  </div>
  )
}
